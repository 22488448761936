import CustomPlugins from '@/components/CustomPlugins/translationPluginMixin';

import Vue from 'vue';

const key = 'projectLanguage';

export const LanguageVue = new Vue({
  data() {
    return {
      language: this.getLanguage(),
    };
  },

  created: function () {
    this.$on(key, this.updateLanguage);
  },

  methods: {
    getLanguage: function () {
      var language = localStorage.getItem(key);

      if (language == null) {
        language = navigator.language || navigator.userLanguage;
        language = language.substring(0, 2);
        localStorage.setItem(key, language);
      }

      return language;
    },

    updateLanguage: function (language) {
      localStorage.setItem(key, language);
      this.language = language;
    },
  },
});

export default {
  mixins: [CustomPlugins],

  methods: {
    getFieldValue: function (value) {
      return typeof value == 'boolean' ? this.$t(value) : value;
    },

    getLanguage: function () {
      return LanguageVue.getLanguage();
    },

    getNextLanguage: function () {
      var nextLanguage = LanguageVue.getLanguage();
      return nextLanguage == 'fr' ? 'en' : 'fr';
    },

    getNextLanguageDisplayName: function () {
      var nextLanguage = LanguageVue.getLanguage();
      return nextLanguage == 'fr' ? 'English' : 'Français';
    },

    changeLanguage: function (language) {
      LanguageVue.$emit(key, language);
    },

    $t: function (key) {
      var userLang = LanguageVue.language;
      var data = {
        en: {
          administration: 'Administration',
          forgotPassword: 'Forgot your password?',
          usernameEmail: 'Email or username',
          confirmationEmail: 'Confirmation Email',
          resetPassword: 'Reset Password',
          passwordResetMessage: 'Your password has been reseted, you will receive an email with your new password',
          signIn: 'Sign In',
          mfaTitle: 'Two-Step Verification',
          mfaText: 'Enter the One Time Password (OTP) generated by your Authenticator App',
          mfaCode: 'Code',
          resetPasswordCode: 'Code',
          invalidCode: 'The entered code is invalid',
          invalidCredentials: 'Invalid email or password',
          forgotPasswordTitle: 'Forgot Your Password?',
          forgotPasswordText: 'Enter your email address and we will send a message to reset your password',
          changePasswordTitle: 'Change Password',
          changePasswordText: 'Enter your new password',
          unknownLoginError: 'An unexpected error occured',
          confirmForgotPasswordText: 'Enter the code that was emailed to you and your new password',
          newPassword: 'New Password',
          newPasswordConfirm: 'Confirm New Password',
          changePassword: 'Change Password',
          passwordMismatch: 'Does not match new password',
          invalidPasswordStructure: 'Password does not meet security requirements',
          passwordRequirements:
            'The password must have:\n- at least 1 digit\n- at least 1 special character\n- at least 1 capital letter\n- at least 1 lowercase letter\n- a minimum length of 8 characters',
          limitExceeded: 'Retry limit exceeded, please try again later',
          alreadyAuthenticated: 'You are already authenticated',
          expiredCode: 'This code has already been used once',
          cancel: 'Cancel',
          save: 'Save',
          next: 'Next',
          precedent: 'Previous',
          copy: 'Copy',
          paste: 'Paste',
          done: 'Done',
          move: 'Move',
          edit: 'Edit',
          delete: 'Delete',
          apply: 'Apply',
          version: 'Version',
          versionShort: 'V.',
          dateSaved: 'Date saved',
          date: 'Date',
          modifiedAt: 'Date modified',
          add: 'Add',
          title: 'Title',
          backPrecedent: 'Back',
          trash: 'Trash',
          search: 'Search',
          path: 'Path',
          filters: 'Filters',
          detail: 'Detail',
          heartRateDetails: 'Heart Rate Details',
          bodyTemperatureDetails: 'Body Temperature Details',
          respiratoryRateDetails: 'Respiratory Rate Details',
          weightDetails: 'Weight Details',
          bloodPressure: 'Blood Pressure',
          bloodPressureDetails: 'Blood Pressure Details',
          systolic: 'Systolic Blood Pressure',
          diastolic: 'Diastolic Blood Pressure',
          time: 'Date and Time',
          saturation: 'Saturation',
          saturationDetails: 'Saturation Details',
          heartRateThresholds: 'Active Heart Rate Threshold(s)',
          bodyTemperatureThresholds: 'Active Body Temperature Threshold(s)',
          respiratoryRateThresholds: 'Active Respiratory Rate Threshold(s)',
          weightThresholds: 'Active Weight Threshold(s)',
          saturationThresholds: 'Active Saturation Threshold',
          systolicThresholds: 'Active Systolic Threshold(s)',
          diastolicThresholds: 'Active Diastolic Threshold(s)',
          thresholdText: 'Alert Threshold',
          threshold: 'Alert Threshold Configuration',
          alertType: 'Alert Type',
          vocalMessages: 'Vocal Messages',
          Français: 'French',
          English: 'English',
          dateLimitText: 'Last',
          unitWeight: 'Weight Unit',
          unitData: 'Alert Unit',
          kilogram: 'Kilogram',
          pound: 'Pound',
          delimiterLegend: 'Last 24 Hours',
          configuration: 'Configuration',
          management: 'Management',
          cardiacFrequency: 'Cardiac Frequency',
          fallDetection: 'Fall Detection',
          bodyTemperature: 'Body Temperature',
          respiratoryRate: 'Respiratory Rate',
          weight: 'Weight',
          anamnesis: 'CI Anamnesis',
          postOpAnamnesis: 'Post Hosp Anamnesis',
          rxAdherence: 'Medication Adherence',
          messages: 'Messages',
          message: 'Message',
          confirmation: 'Confirmation',
          status: 'Status',
          select: 'Select',
          selectAll: 'select all',
          print: 'Print',
          of: 'of',
          filter: 'Filter',
          filterByColumn: 'Filter by column',
          column: 'Column',
          repicient: 'Recipient',
          applyFilters: 'Apply filters',
          filterByRights: 'Filter by user rights',
          logout: 'Logout',
          login: 'Sign In',
          notificationsMenu: 'Notifications',
          markAllNotifAsRead: 'Mark all as read',
          deleteAll: 'Delete all',
          providerMessage: 'Please sign in with your account',
          alternativeMessage: 'Or sign in below',
          code: 'Room',
          from: 'From',
          to: 'To',
          name: 'Name',
          address: 'Address',
          updateList: 'Update the list',

          id: 'ID',
          none: 'No Data',
          confirmationIgnore: 'Discard',
          confirmationQuestion: 'Do you want to discard unsaved changes?',
          requiredFieldsLegend: '* indicates a required field',
          onlyNormativeActivities: 'Only normative activities are visible because the protocol is normative',

          //Proximity
          dataAdmin: 'Administration Dashboard',
          residency: 'Residency',
          residencies: 'Residencies',
          residencyName: 'Residency Name',
          residencyAddress: 'Residency Address',
          adminUsers: 'Administrators',
          database: 'Database',
          tenants: 'Tenants',
          createApiKey: 'Add Api Key',
          editApiKey: 'Edit Api Key',
          deleteKeyQuestion: 'Are you sure you want to delete this Api Key?',
          apiKeys: 'Api Keys',
          databaseName: 'Database Name',
          keyValue: 'Key Value',
          professionalManagement: 'Healthcare Professionals',
          organization: 'Organization',
          facility: 'Facility',
          postalCode: 'Postal Code',
          normative: 'Normative',
          description: 'Description',
          activityType: 'Activity Type',
          parametersType: 'Parameter Type',
          continous: 'Continuously',
          dosage: 'Dosage',
          form: 'Form',

          subscriptions: 'Member List',
          residentFirstName: 'User First Name',
          residentLastName: 'User Name',
          residentBirth: 'User Birth Date',
          isEnable: 'Member Subscribed',
          resident: 'User',
          availableResidents: 'Available users',

          years: 'Years',
          year: 'Year',
          months: 'Months',
          month: 'Month',
          weeks: 'Weeks',
          week: 'Week',
          days: 'Days',
          day: 'Day',
          hours: 'Hours',
          hour: 'Hour',
          minutes: 'Minutes',
          minute: 'Minute',

          createSubscription: 'Create Subscription',
          editSubscription: 'Edit Subscription',
          seeSubscription: 'See Subsdcription',
          residentFilter: 'User Filter',
          phone: 'Phone/Mobile',
          role: 'Role',
          relationship: 'Relation',
          emailAccounting: 'Accounting Email',
          emailCareManagement: 'Care Management Email',
          emailReception: 'Reception Email',
          emailExecutingManagement: 'Executing Management Email',
          requiredField: 'This field is required',
          hoursDays: 'Hours/days Value',
          maxHoursDaysValue: 'The value must be between 1 and',

          NOT_DEFINED: 'Undefined',

          createdDate: 'Created At',
          createdBy: 'Created By',
          modifiedDate: 'Modified At',
          modifiedBy: 'Modified By',

          availableUsers: 'Virtuose User',

          yes: 'Yes',
          no: 'No',
          trashTip: 'Remove this item',
          confirmAction: 'Confirm action',

          remove: 'Remove',
          removeAll: 'Remove All',

          removeQuestion: 'Are you sure you want to delete this item?',
          deleteConfirmation: 'Deletion confirmation',

          value: 'Value',
          resultByPage: 'Results per page',

          wait: 'Wait',
          success: 'Success',
          error: 'Error',
          reason: 'Reason',

          errorNetwork:
            'A communication error occurred while communicating with the server. If the problem persists, check your internet connection',
          error401: 'You are not authenticated',
          error403: "You don't have the required access rights",
          ErrorNormativeActivityCannotBeUpdated: 'The activity is normative so it cannot be updated',

          nonNormativeActivityAssociatedProtocol: 'The protocol is associated to one or more non-normative activities',
          nonNormativeDialogAssociatedActivity: 'The activity is associated to one or more non-normative dialogs',
          nonNormativeDialogueInNormativeActivity: 'The activity is normative but one or more dialogs are not',
          vocalMessageInNormativeActivity: 'The vocal message is associated to a normative activity',
          nonNormativeVMInNormativeActivity: 'The protocol is normative but one or more vocal messages are not',
          nonNormativeProtocolActivity: 'The protocol is normative but one or more activites are not',
          dialogueInNormativeActivity: 'The dialog is associated to a normative activity',
          activityAssociatedNormativeProtocol: 'The activity is associated to a normative protocol',

          closeDialog: 'Close dialog',
          close: 'Close',
          pageNumber: 'Page Number',
          previousPage: 'Previous page',
          nextPage: 'Next page',
          displayMenu: 'Display Menu',
          closeMenu: 'Close menu',
          resultIndex: 'Results range',
          nbResults: 'Total Number of Results',
          paginationPagesNumber: '{{startIndex}}-{{endIndex}} of {{totalData}}',
          all: 'All',

          userDetails: 'User Detail',
          username: 'Username',
          firstName: 'First Name',
          lastName: 'Last Name',
          mrn: 'Medical Record Number',
          active: 'Active',
          gender: 'Gender',
          email: 'Email',
          type: 'Type',
          emails: 'Emails',
          emailSent: 'The email has been sent',
          lastLogin: 'last login',
          emailVerified: 'Email confirmed',
          country: 'Country',
          province: 'Province',
          installation: 'Facility',
          establishment: 'Organization',
          room: 'Room',
          roombed: 'Room - Bed',
          bed: 'Bed',
          admissionDate: 'Admission Date',
          age: 'Age',
          pulse: 'Heart Rate',
          ecg: 'ECG',
          temperature: 'Temperature',
          city: 'City',
          deathDate: 'Death Date',
          created_at: 'Created at',
          created_by: 'Created by',
          updated_at: 'Updated at',
          updated_by: 'Updated by',
          newUser: 'New user',
          deleteUser: 'Remove user',
          confirmDeleteUser: 'Do you really want to delete the user?',
          min: 'MIN',
          noDataInTable: 'No data in this table',
          noDataAvailable: 'No data available',
          homecare: 'Homecare',
          inpatient: 'Inpatient',
          residencyuser: 'Resident',
          patientDetails: 'Patient Details',
          noInformation: 'No Information',
          userPreferences: 'User Preferences',
          notifications: 'Notifications',

          modifyUserRights: 'Modify User Rights',
          modifyRights: 'Modify Rights',
          modifyPilotingRights: 'Modify App Admin Rights',
          loggedUserDetails: 'Logged User Update Panel',
          updateUserInfo: 'Update User Info',
          password: 'Password',
          oldPassword: 'Old Password Or Verification Code',

          //axios errors
          'Network Error': 'Network Error',
          'Failed to fetch': 'Network Error',

          //Mail
          mailLog: 'Email Log',
          subject: 'Subject',
          delivered: 'Delivred',
          accepted: 'Accepted',
          recipient: 'Recipients',
          rejected: 'Rejected',
          failed: 'Failed',
          opened: 'Opened',
          clicked: 'Clicked',
          unsubscribed: 'Unsubscribed',
          complained: 'Complained',
          stored: 'Stored',

          rowsByPage: 'Rows By Page',

          //Monitoring
          at: 'Date',
          alertDate: 'Date',
          statusLevelId: 'Status',

          reset: 'Reset',
          clearFilters: 'Clear filters',

          errorMissingParameters: 'The following parameters are missing:',
          errorTenantNameNotProvided: 'The database name must be provided',
          errorDbDoesNotExistOrInvalid: 'The database/tenant (%s) does not exist or is broken',
          errorResidentDoesNotExist: 'Resident/User does not exist',
          errorResidencyDoesNotExist: 'Residency does not exist',
          errorProfessionalDoesNotExist: 'Professional does not exist',
          errorUserDoesNotExist: 'Member does not exist',
          errorMemberDoesNotExist: 'Member does not exist',
          errorInvalidRole: 'Invalid role',
          errorSubscriptionIdIsNull: 'The subscription id is null',
          errorSubscriptionIdExists: 'The subscription already exists',
          errorSubscriptionDoesNotExist: 'Subscription does not exists',
          errorSubscriptionAlreadyExistForMemberResident:
            'A subscription with this member and resident/user already exists',
          errorInvalidDateFormat: 'Date format is incorrect, format must be YYYY-MM-DD',
          errorFirstNameEmpty: 'First name must be specified',
          errorLastNameEmpty: 'Last name must be specified',
          errorEmailNameEmpty: 'Email address must be specified',
          errorPhoneEmpty: 'Phone number must be specified',
          errorRelationEmpty: 'Relation must be specified',
          networkError: 'Server unreachable',
          invalidLogin: 'Your login failed. login and/or password is incorrect..',
          unknown: 'Unknown error',
          error404: 'The resource cannot be found',
          errorAuthentificationFailed: 'Authentication failed',
          errorResidencyAlreadyExists: 'A residency with this ID already exists',
          errorUserAlreadyExist: 'An user with this username/email already exists',
          errorPasswordMustBeChanged: 'The user password must be changed',
          errorMemberIsDisabled: 'The subscription has been disabled',
          errorApiKeyExists: 'An Api Key already exist for this database',
          errorTenantDoesNotExists: 'The database does not exists',
          errorPatientDoesNotExist: 'The requested patient does not exist',

          validationRulesGeneral: 'The value is not valid',
          validationRulesRequired: 'This field is required',
          validationRulesEmail: 'The email address is not valid',
          validationRulesAtMost: 'The value should be less or equal to {max}',
          validationRulesAtLeast: 'The value should be greater or equal to {min}',
          validationRulesInRange: 'The value should be between {min} and {max} inclusively',
          validationRulesMaxLength: 'The maximum number of characters is',
          validationRulesInteger: 'The value should be an integer',
        },
        fr: {
          administration: 'Administration',
          administrators: 'Administrateurs',
          forgotPassword: 'Mot de passe oublié ?',
          usernameEmail: "Adresse de courriel ou code d'utilisateur",
          confirmationEmail: 'Adresse courriel pour la confirmation',
          resetPassword: 'Réintialisé le mot de passe',
          passwordResetMessage:
            'Votre mot de passe à été ré-initialisé, vous receverez un courriel avec votre nouveau mot de passe',
          signIn: 'Connexion',
          mfaCode: 'Code',
          resetPasswordCode: 'Code',
          mfaTitle: 'Vérification en deux étapes',
          mfaText: "Veuillez saisir le code unique (OTP) généré par votre application d'authentification",
          invalidCode: 'Le code saisi est invalide',
          invalidCredentials: "Le courriel ou le mot de passe n'est pas valide",
          invalidPasswordStructure: 'Le mot de passe ne satisfait pas les règles de sécurité',
          passwordRequirements:
            'Le mot de passe doit avoir:\n- au moins 1 chiffre\n- au moins 1 caractère spécial\n- au moins 1 lettre majuscule\n- au moins 1 lettre minuscule\n- une longueur minimale de 8 caractères',
          unknownLoginError: "Une erreur inattendue s'est produite",
          forgotPasswordTitle: 'Mot de passe oublié?',
          forgotPasswordText:
            'Entrez votre courriel et nous vous enverrons un message pour réinitialiser votre mot de passe',
          changePasswordTitle: 'Changer le mot de passe',
          changePasswordText: 'Entrez votre nouveau mot de passe',
          confirmForgotPasswordText:
            'Entrez le code qui vous a été envoyé par courriel ainsi que votre nouveau mot de passe',
          newPassword: 'Nouveau mot de passe',
          newPasswordConfirm: 'Confirmation du nouveau mot de passe',
          changePassword: 'Modifier le mot de passe',
          passwordMismatch: 'Ne correspond pas au nouveau mot de passe',
          limitExceeded: 'La limite de tentative a été dépassée, veuillez réessayer plus tard.',
          alreadyAuthenticated: 'Vous êtes déjà authentifié',
          expiredCode: 'Ce code a déjà été utilisé une fois',
          zonehome: 'Zone',
          zones: 'Zones',
          cancel: 'Annuler',
          save: 'Enregistrer',
          next: 'Suivant',
          precedent: 'Précédent',
          copy: 'Copier',
          paste: 'Coller',
          done: 'Terminer',
          move: 'Déplacer',
          edit: 'Modifier',
          delete: 'Supprimer',
          apply: 'Appliquer',
          version: 'Version',
          versionShort: 'V.',
          dateSaved: 'Date de la sauvegarde',
          date: 'Date',
          modifiedAt: 'Date de modification',
          add: 'Ajouter',
          title: 'Titre',
          backPrecedent: 'Retour',
          trash: 'Corbeille',
          search: 'Rechercher',
          path: 'Chemin',
          filters: 'Filtres',
          detail: 'Détail',
          heartRateDetails: 'Détails de la fréquence cardiaque',
          bodyTemperatureDetails: 'Détails de la température corporelle',
          respiratoryRateDetails: 'Détails de la fréquence respiratoire',
          weightDetails: 'Détails du poids',
          bloodPressure: 'Pression Artérielle',
          bloodPressureDetails: 'Détails de la pression artérielle',
          systolic: 'Tension artérielle systolique',
          diastolic: 'Tension artérielle diastolique',
          time: 'Date et heure',
          saturation: 'Saturation',
          saturationDetails: 'Détails de la saturation',
          heartRateThresholds: 'Seuil(s) actif(s) fréquence cardiaque',
          bodyTemperatureThresholds: 'Seuil(s) actif(s) température corporelle',
          respiratoryRateThresholds: 'Seuil(s) actif(s) fréquence respiratoire',
          weightThresholds: 'Seuil(s) actif(s) poids',
          saturationThresholds: 'Seuil actif saturation',
          systolicThresholds: 'Seuil(s) actif(s) systolique',
          diastolicThresholds: 'Seuil(s) actif(s) diastolique',
          thresholdText: "Seuil d'alerte",
          threshold: "Configuration de seuil d'alerte",
          alertType: "Type d'alerte",
          vocalMessages: 'Messages vocaux',
          Français: 'Français',
          English: 'Anglais',
          dateLimitText: 'Dernier(ères) ',
          unitWeight: 'Unité du poids',
          unitData: "Unité de l'alerte",
          kilogram: 'Kilogramme',
          pound: 'Livre',
          delimiterLegend: 'Dernières 24 heures',
          configuration: 'Configuration',
          management: 'Gestion',
          cardiacFrequency: 'Fréquence cardiaque',
          fallDetection: 'Détection Chute',
          bodyTemperature: 'Température corporelle',
          respiratoryRate: 'Fréquence respiratoire',
          weight: 'Poids',
          anamnesis: 'Anamnèse IC',
          postOpAnamnesis: 'Anamnèse Post-Hospit.',
          rxAdherence: 'Adhérence RX',
          messages: 'Messages',
          message: 'Message',
          print: 'Imprimer',
          of: 'de',
          filter: 'Filtre',
          filterByColumn: 'Filtre par colonne',
          column: 'Colonne',
          confirmation: 'Confirmation',
          status: 'Statut',
          select: 'Sélectionner',
          selectAll: 'Sélectionner Tout',
          repicient: 'Destinataire',
          applyFilters: 'Appliquer les filtres',
          filterByRights: "Filtre par droits d'utilisateur",
          logout: 'Se déconnecter',
          login: 'Se connecter',
          notificationsMenu: 'Notifications',
          markAllNotifAsRead: 'Tout marquer comme lu',
          deleteAll: 'Tout supprimer',

          providerMessage: 'Connectez vous avec votre compte',
          alternativeMessage: 'Ou connectez vous ici',
          code: 'Local',
          from: 'De',
          to: 'Vers',
          name: 'Nom',
          address: 'Adresse',

          updateList: 'Actualiser la liste',

          id: 'Identifiant',
          none: 'Non Spécifié',
          confirmationIgnore: 'Ignorer',
          confirmationQuestion: 'Ignorer les modifications non enregistrées?',
          requiredFieldsLegend: '* indique un champ obligatoire',
          onlyNormativeActivities: 'Seules les activités normatives sont visibles car le protocole est normatif',

          //Proximity
          dataAdmin: "Console d'administration",
          residency: 'Résidence',
          residencies: 'Résidences',
          residencyName: 'Nom de la résidence',
          residencyAddress: 'Adresse de la résidence',
          adminUsers: 'Administrateurs',
          database: 'Base de données',
          tenants: 'Bases de données',
          createApiKey: 'Ajouter une clé',
          editApiKey: 'Modifier une clé',
          deleteKeyQuestion: 'Êtes-vous certain de vouloir effacer cette clé ?',
          apiKeys: "Clés d'Api",
          databaseName: 'Base de données',
          keyValue: 'Valeur de la clé',
          professionalManagement: 'Intervenants',
          organization: 'Établissement',
          facility: 'Installation',
          postalCode: 'Code postal',
          normative: 'Normatif',

          subscriptions: 'Liste des membres',
          residentFirstName: "Prénom de l'usager",
          residentLastName: "Nom de l'usager",
          residentBirth: "Date de naissance de l'usager",
          admissionDate: "Date d'admission",
          age: 'Age',
          isEnable: 'Membre abonné',
          resident: 'Usager',
          availableResidents: 'Usagers disponible',

          years: 'Ans',
          year: 'An',
          months: 'Mois',
          month: 'Mois',
          weeks: 'Semaines',
          week: 'Semaine',
          days: 'Jours',
          day: 'Jour',
          hours: 'Heures',
          hour: 'Heure',
          minutes: 'Minutes',
          minute: 'Minute',

          createSubscription: 'Ajouter un abonnement',
          editSubscription: 'Modifier un abonnement',
          seeSubscription: 'Voir un abonnement',
          residentFilter: 'Filtre pour les usagers',
          phone: 'Téléphone/Mobile',
          role: 'Rôle',
          relationship: 'Relation',
          emailAccounting: 'Courriel de la comptabilité',
          emailCareManagement: 'Courriel de la direction de soins',
          emailReception: 'Courriel de la réception',
          emailExecutingManagement: 'Courriel de direction générale',
          requiredField: 'Ce champ est requis',
          hoursDays: "Nombre d'heures/jours",
          maxHoursDaysValue: 'La valeur doit être entre 1 et',

          NOT_DEFINED: 'Indefini',

          createdDate: 'Créé le',
          createdBy: 'Créé par',
          modifiedDate: 'Modifié le',
          modifiedBy: 'Modifié par',

          availableUsers: 'Utilisateur Virtuose',

          back: 'Retour en arrière',
          backTip: 'Permet de revenir au dossier parent',

          close: 'Fermer',
          description: 'Description',
          activityType: "Type d'activité",
          parametersType: 'Type de paramètre',
          continous: 'En continu',
          dosage: 'Posologie',
          form: 'Forme',
          comments: 'Commentaires',

          yes: 'Oui',
          no: 'Non',
          trashTip: 'Enlever cet item',
          confirmAction: "Confirmer l'action",

          seeItemInfo: 'Voir les informations',
          remove: 'Enlever',
          removeAll: 'Tout Enlever',

          removeQuestion: 'Êtes-vous certain de vouloir effacer cet item ?',
          deleteConfirmation: 'Confirmer la suppression',

          value: 'Valeur',
          resultByPage: 'Résultats par page',
          details: 'Détails',

          wait: 'Attendez',
          success: 'Réussite',
          error: 'Erreur',
          reason: 'Cause',

          errorNetwork:
            'Un problème de communication avec le serveur est survenu. Si le problème persiste vérifiez votre connexion internet',
          error401: "Vous n'êtes pas authentifié",
          error403: "Vous ne possédez pas les droits d'accès requis",
          ErrorNormativeActivityCannotBeUpdated: "L'activité est normative et ne peut donc pas être modifiée",

          nonNormativeActivityAssociatedProtocol:
            'Le protocole est associé à une ou plusieurs activités non-normatives',
          nonNormativeDialogAssociatedActivity: "L'activité est associé à un ou plusieurs dialogues non-normatifs",
          nonNormativeDialogueInNormativeActivity:
            "L'activité est normative mais un ou plusieurs dialogues ne le sont pas",
          vocalMessageInNormativeActivity: 'Le message vocal est associé à une activité normative',
          nonNormativeVMInNormativeActivity:
            "L'activity est normative mais un ou plusieurs messages vocaux ne le sont pas",
          nonNormativeProtocolActivity: 'Le protocole est normatif mais une ou plusieurs activités ne le sont pas',
          dialogueInNormativeActivity: 'Le dialogue est associé à une activité normative',
          activityAssociatedNormativeProtocol: "L'activité est associée à un protocol normatif",

          closeDialog: 'Fermer la fenêtre de dialog',
          pageNumber: 'Numéro de la page',
          previousPage: 'Page précédente',
          nextPage: 'Page suivante',
          paginationPagesNumber: '{{startIndex}}-{{endIndex}} sur {{totalData}}',
          all: 'Tout',

          userDetails: "Détail de l'utilisateur",
          username: "Nom de l'utilisateur",
          firstName: 'Prénom',
          lastName: 'Nom',
          mrn: 'Numéro de dossier',
          active: 'Actif',
          installation: 'Installation',
          establishment: 'Établissement',
          room: 'Chambre',
          bed: 'Lit',
          roombed: 'Chambre - Lit',
          deathDate: 'Date de décès',
          created_at: 'Créé à',
          created_by: 'Créé par',
          updated_at: 'Mis à jour à',
          updated_by: 'Mis à jour par',
          country: 'Pays',
          province: 'Province',
          city: 'Ville',
          pulse: 'Fréquence cardiaque',
          ecg: 'ECG',
          temperature: 'Température',
          homecare: 'Usager',
          inpatient: 'Hospitalisé',
          residencyuser: 'Résident',
          patientDetails: 'Détails du patient',
          noInformation: "Pas d'information",
          userPreferences: 'Préférences utilisateur',
          notifications: 'Notifications',

          gender: 'Genre',

          email: 'Courriel',
          type: 'Type',
          emails: 'Courriels',
          serverLog: 'Journalisation du serveur',
          emailSent: 'Le courriel a été envoyé',
          lastLogin: 'Dernière connection',
          emailVerified: 'Courriel confirmé',
          landingPageUrl: "URL de la page d'accueil",
          landingPageUrlShort: "URL page d'accueil",
          updateLandingPageUrl: "Changer l'URL de la page d'accueil",
          defaultLandingPage: "URL de la page d'accueil par défaut",
          socialConnectionText: 'Texte à afficher pour la connexion par Fournisseurs',
          passwordConnectionText: 'Texte à afficher pour la connexion par utilisateur et mot de passe',
          providerName: 'Nom du fournisseur',
          providers: 'Fournisseurs',
          availableProviders: 'Fournisseurs disponibles',
          currentProviders: 'Fournisseurs actuels',
          loginPageContent: 'Contenu de la page de connexion',
          newUser: 'Nouvel utilisateur',
          deleteUser: "Supprimer l'utilisateur",
          confirmDeleteUser: "Voulez vous vraiment supprimer l'utilisateur ?",
          event: 'Événement',
          min: 'NAM',
          noDataInTable: 'Aucune donnée dans cette table',
          noDataAvailable: 'Aucune donnée',

          updateUserInfo: 'Mettre à jour',
          password: 'Mot de passe',
          oldPassword: 'Ancien mot de passe OU code de vérification',

          //axios errors
          'Network Error': 'Erreur avec le réseau',
          'Failed to fetch': 'Erreur avec le réseau',

          //Mail
          mailLog: 'Journalisation de courriel',
          subject: 'Sujet',
          delivered: 'Livré',
          accepted: 'Accepté',
          recipient: 'Destinataires',
          rejected: 'Rejeté',
          failed: 'Échoué',
          opened: 'Ouvert',
          clicked: 'Cliqué',
          unsubscribed: 'Désabonné',
          complained: 'Plainte',
          stored: 'Enregistré',

          rowsByPage: 'Résultats par page',

          //Monitoring
          at: 'Date',
          alertDate: 'Date',
          statusLevelId: 'Statut',

          reset: 'Réinitialiser',
          clearFilters: 'Effacer filtres',

          errorMissingParameters: 'Les paramètres suivants sont manquants :',
          errorUserNameNotProvided: "L'identificateur de l'utilisateur doit être spécifié",
          errorUserEmailNotProvided: "L'adresse courriel de l'utilisateur doit être spécifié",
          errorEmailInvalidString: "L'adresse courriel est invalide",
          errorUserFirstNameNotProvided: "Le prénom de l'utilisateur doit être spécifié",
          errorUserLastNameNotProvided: "Le nom de l'utilisateur doit être spécifié",
          errorUserNameExist: "L'utilisateur (%s) existe déjà",
          errorUserEmailExist: "L'adresse courriel (%s) existe déjà",

          errorTenantNameNotProvided: 'Le nom de la base de données doit être spécifié',
          errorDbDoesNotExistOrInvalid: "La base de données (%s) n'existe pas ou est endommagée",
          errorResidentDoesNotExist: "Le résident/usager n'existe pas",
          errorResidencyDoesNotExist: "La résidence n'existe pas",
          errorProfessionalDoesNotExist: "Le professionel n'existe pas",
          errorUserDoesNotExist: "Le membre n'existe pas",
          errorMemberDoesNotExist: "Le membre n'existe pas",
          errorInvalidRole: 'Le rôle est invalide',
          errorSubscriptionIdIsNull: "L'identifiant de souscription est null",
          errorSubscriptionIdExists: "L'identifiant de souscription existe déjà",
          errorSubscriptionDoesNotExist: "La souscription n'existe pas",
          errorSubscriptionAlreadyExistForMemberResident:
            'Une souscription avec ce membre et ce résident/usager existe déjà',
          errorInvalidDateFormat: 'Le format de la date est incorrect, le format doit être AAAA-MM-JJ',
          errorFirstNameEmpty: 'Le prénom doit être spécifié',
          errorLastNameEmpty: 'Le nom doit être spécifié',
          errorEmailNameEmpty: 'Le courriel doit être spécifié',
          errorPhoneEmpty: 'Le numéro de téléphone doit être spécifié',
          errorRelationEmpty: 'La relation doit être spécifié',
          networkError: 'Erreur de connection avec le serveur',
          invalidLogin: 'Adresse courriel ou mot de passe invalide. Veuillez réessayer.',
          unknown: 'Erreur inconnue',
          error404: "La ressource n'existe pas",
          errorAuthentificationFailed: "L'authentification a échouée",
          errorResidencyAlreadyExists: 'Une residence avec cet identifiant existe déjà',
          errorUserAlreadyExist: 'Un utilisateur avec ce nom/courriel existe déjà',
          errorPasswordMustBeChanged: "Le mot de passe de l'utilisateur doit être changé",
          errorMemberIsDisabled: "L'abonnement a été désactivé",
          errorApiKeyExists: "Une clé d'Api existe déjà pour cette base de données",
          errorTenantDoesNotExists: "La base de données n'existe pas",
          errorPatientDoesNotExist: "Le patient demandé n'existe pas",

          validationRulesGeneral: "La valeur saisie n'est pas valide",
          validationRulesRequired: 'Ce champ est requis',
          validationRulesEmail: "L'adresse courriel n'est pas valide",
          validationRulesAtMost: 'La valeur doit être plus petite ou égale à {max}',
          validationRulesAtLeast: 'La valeur doit être plus grande ou égale à {min}',
          validationRulesInRange: 'La valeur doit se situer entre {min} et {max} inclusivement',
          validationRulesMaxLength: 'Le nombre maximal de caractères autorisé est',
          validationRulesInteger: 'Le nombre doit être un entier',
        },
      };

      var langageTranslations = data[userLang];

      var result = key;

      if (key === true) {
        if (userLang == 'fr') {
          result = 'Oui';
        }
        if (userLang == 'en') {
          result = 'Yes';
        }
      } else if (key === false) {
        if (userLang == 'fr') {
          result = 'Non';
        }
        if (userLang == 'en') {
          result = 'No';
        }
      } else {
        if (Object.prototype.hasOwnProperty.call(langageTranslations, key)) {
          result = langageTranslations[key];
        } else {
          var plugTranslation = this.getPluginTranslations(userLang, key);

          if (plugTranslation != null) {
            result = plugTranslation;
          }
        }
      }

      return result;
    },
  },
};
