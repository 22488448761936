var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-center",class:{
    'width-100': _vm.activityTypeCode === _vm.activityTypes.WEI && _vm.$vuetify.breakpoint.lgAndDown,
  }},[_c('div',{staticClass:"d-flex",class:{
      'flex-column': _vm.activityTypeCode !== _vm.activityTypes.WEI || _vm.$vuetify.breakpoint.xl,
      'justify-space-between':
        _vm.$vuetify.breakpoint.lgAndDown &&
        [_vm.activityTypes.WEI, _vm.activityTypes.FDE, _vm.activityTypes.INC].includes(_vm.activityTypeCode),
      'width-100':
        _vm.$vuetify.breakpoint.lgAndDown &&
        [_vm.activityTypes.WEI, _vm.activityTypes.FDE, _vm.activityTypes.INC].includes(_vm.activityTypeCode),
      'ml-1': _vm.$vuetify.breakpoint.lgAndDown,
    }},[_c('div',{staticClass:"d-flex",class:{
        'justify-center': _vm.$vuetify.breakpoint.xl,
        'justify-space-between': _vm.activityTypeCode === _vm.activityTypes.WEI && _vm.$vuetify.breakpoint.lgAndDown,
        'justify-end': _vm.activityTypeCode !== _vm.activityTypes.WEI || _vm.$vuetify.breakpoint.lgAndDown,
        'grey--text': _vm.isDelayedValue(_vm.patientVitalSign, _vm.activityTypeCode) && _vm.activityTypeCode !== _vm.activityTypes.WEI,
        'red--text': _vm.isVitalSignValueAnAlert(),
      }},[(_vm.isVitalSignValueAnAlert())?_c('v-icon',{class:_vm.$vuetify.breakpoint.lgAndDown ? 'pr-1' : 'pr-2',attrs:{"color":"red","small":""}},[_vm._v("mdi-alert-circle ")]):_vm._e(),_vm._l((_vm.patientVitalSign.patientActivityIotRealtime[_vm.activityTypeCode].values),function(value,valueIndex){return _c('div',{key:valueIndex},[_c('div',[_vm._v(" "+_vm._s(value + (valueIndex !== _vm.patientVitalSign.patientActivityIotRealtime[_vm.activityTypeCode].values.length - 1 ? '/' : ''))+" ")])])}),(![_vm.activityTypes.SAT, _vm.activityTypes.BDT].includes(_vm.activityTypeCode))?_c('span',[_vm._v(" ")]):_vm._e(),_vm._v(_vm._s(_vm.$t(`${_vm.activityTypeCode}Unit`))+" ")],2),(_vm.activityTypeCode === _vm.activityTypes.WEI || _vm.isDelayedValue(_vm.patientVitalSign, _vm.activityTypeCode))?_c('div',{staticClass:"text--secondary text-body-2 d-flex",class:{
        'text-center': _vm.activityTypeCode === _vm.activityTypes.WEI,
        'justify-end': _vm.activityTypeCode !== _vm.activityTypes.WEI && _vm.$vuetify.breakpoint.lgAndDown,
        'justify-center': _vm.$vuetify.breakpoint.xl,
      }},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({staticClass:"d-flex justify-center align-center",class:{
              'ml-5': _vm.$vuetify.breakpoint.lgAndDown && _vm.activityTypeCode === _vm.activityTypes.WEI,
            }},on),[_vm._v(" "+_vm._s(_vm.getRelativeDate(_vm.patientVitalSign.patientActivityIotRealtime[_vm.activityTypeCode].timeDifference))+" ")])]}}],null,false,1546849969)},[_c('span',[_vm._v(" "+_vm._s(_vm.getFormatDate(_vm.patientVitalSign.patientActivityIotRealtime[_vm.activityTypeCode].lastDataDatetime)))])])],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }