class PatientResourcesFormData {
  constructor() {
    this.reset();
  }

  reset() {
    this.id = null;
    this.resourceName = '';
    this.resourceDescription = '';
    this.resourceFormat = 'Document/PDF'; // Todo: should be dynamic if we support other format
    this.createdAt = null;
    this.modifiedDate = null;
    this.uploadedFile = null;
    this.resourceFile = '';
    this.resourceOriginalFile = '';
  }

  setData(data) {
    this.id = data.id;
    this.resourceName = data.resourceName;
    this.resourceDescription = data.resourceDescription;
    this.resourceFormat = data.resourceFormat;
    this.createdAt = data.createdAt;
    this.modifiedDate = data.modifiedDate;
    this.resourceFile = data.resourceFile;
    this.resourceOriginalFile = data.resourceOriginalFile;
  }
}

export { PatientResourcesFormData };
