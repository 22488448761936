<template>
  <v-icon
    v-if="dataTypeCode === activityTypes.INC || dataTypeCode === activityTypes.FDE"
    icon
    :size="size"
    :class="dataTypeCode === activityTypes.FDE ? 'mdi-rotate-315' : ''"
  >
    {{ getIconOnDataTypeCode() }}
  </v-icon>

  <img v-else :src="healthIconsSource + getIconOnDataTypeCode()" :alt="dataTypeCode + '-icon'" :height="size + 'px'" />
</template>

<script>
import { ActivityTypes } from '@/components/PatientMonitoring/constants';
import { RealtimeDataTypeCodes, RealtimeVitalSignsCodes } from '@/components/PatientIotRealtime/constants.js';
export default {
  name: 'IotRealtimeIcon',

  props: {
    dataTypeCode: {
      required: true,
      type: String,
    },

    size: {
      required: false,
      type: Number,
      default: 21,
    },
  },

  data() {
    return {
      activityTypes: ActivityTypes,
      healthIconsSource: '/static/healthIcons/',
      realtimeVitalSignsCodes: RealtimeVitalSignsCodes,
      iotRealtimeIcons: {
        [RealtimeDataTypeCodes.APR]: 'blood_pressure.svg',
        [RealtimeDataTypeCodes.BDT]: 'thermometer.svg',
        [RealtimeDataTypeCodes.CFR]: 'heart.svg',
        [RealtimeDataTypeCodes.ECG]: 'cardiogram.svg',
        [RealtimeDataTypeCodes.FDE]: 'mdi-run',
        [RealtimeDataTypeCodes.INC]: 'mdi-diaper-outline',
        [RealtimeDataTypeCodes.RES]: 'lungs.svg',
        [RealtimeDataTypeCodes.SAT]: 'saturation.svg',
        [RealtimeDataTypeCodes.WEI]: 'weight.svg',
      },
    };
  },

  methods: {
    getIconOnDataTypeCode: function () {
      return this.iotRealtimeIcons[this.dataTypeCode] || '';
    },
  },
};
</script>
