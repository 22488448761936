<template>
  <div>
    <ErrorModal :error="error" @close-error-modal="error = null" />

    <div class="chart-container">
      <WaitModal :show="showWaitModal" class="wait-modal" />

      <Chart v-if="areChartDataMounted" ref="vitalSignsChart" :chart-options="chartOptions" :chart-data="chartData" />
    </div>
  </div>
</template>

<script>
import { Bar as Chart } from 'vue-chartjs';
import { Chart as ChartJS, Decimation, LinearScale, LineController, LineElement, TimeScale, Tooltip } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';

import 'chartjs-adapter-date-fns';
import { addHours, addMinutes, differenceInMinutes, differenceInSeconds, format, setSeconds } from 'date-fns';

import iotRealtimeService from '@/services/iotRealtimeService';

import translationMixin, { LanguageVue } from '@/translationMixin';

import { chartColor } from '@/components/PatientMonitoring/Chart/chartColor';
import chartMixin from '@/components/PatientMonitoring/Chart/chartMixin.js';
import { RealtimeDataTypeCodes } from '@/components/PatientIotRealtime/constants.js';

import vitalSignsChartsEventBus from '@/components/PatientIotRealtime/Details/vitalSignsChartsEventBus.js';

ChartJS.register(Decimation, LinearScale, LineController, LineElement, TimeScale, Tooltip, zoomPlugin);

export default {
  name: 'VitalSignsChart',

  components: { Chart },
  mixins: [chartMixin, translationMixin],

  props: {
    activityTypeCode: {
      required: true,
      type: String,
    },

    newChartData: {
      type: Object,
      required: false,
      default: null,
    },

    patientData: {
      required: false,
      type: Object,
      default: null,
    },

    xMaxDate: {
      required: true,
      type: String,
    },

    zoomLevel: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      areChartDataMounted: false, // Security measure because the chart could potentially enter an infinite loop
      defaultData: null,
      ecgMillivoltDivisionConstant: 2621.44, // Biobeat constant to convert data into mV
      ecgWorker: null,
      ecgWorkerPath: '/static/workers/ecgChartWorker.js',
      error: null,
      isChartEcg: this.activityTypeCode === RealtimeDataTypeCodes.ECG,
      showWaitModal: false,
      workerQueue: [],

      chartData: {
        datasets: [{}],
      },
      chartOptions: {
        animation: false,
        parsing: false,

        plugins: {
          decimation: {
            enabled: this.isChartEcg, // Limit the number of visible points
            algorithm: 'lttb',
            samples: 1000,
          },
          tooltip: {
            titleAlign: 'center',
            footerAlign: 'center',
            callbacks: {
              title: (chartDatasets) => {
                const language = this.getLanguage();

                if (this.isChartEcg && chartDatasets[0].dataset.type === 'line') {
                  return format(
                    new Date(chartDatasets[0].raw.x),
                    language === 'fr' ? 'yyyy-MM-dd HH:mm:ss.SSS' : 'yyyy-MM-dd hh:mm:ss.SSS b'
                  );
                }

                return format(
                  new Date(chartDatasets[0].raw.x),
                  language === 'fr' ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd hh:mm b'
                );
              },
              label: (data) => {
                if (data.dataset.type === 'line') {
                  const obsType = this.isChartEcg ? data.raw.observationType.toLowerCase() : data.raw.observationType;

                  return `${this.$t(obsType.charAt(0).toLowerCase() + obsType.slice(1))}: ${data.formattedValue}${
                    [RealtimeDataTypeCodes.SAT, RealtimeDataTypeCodes.BDT].includes(this.activityTypeCode) ? '' : ' '
                  }${this.$t(this.activityTypeCode + 'Unit')}`;
                }
              },
              footer: function (chartDatasets) {
                if (chartDatasets[0].dataset.type === 'bar') {
                  return this.$t('iotRealtime.zoomInToSeeData');
                }
              }.bind(this), // Must bind because if we don't "this" is not recognized
            },
          },
          datalabels: {
            display: false,
          },
          legend: {
            display: false,
          },
          zoom: {
            zoom: {
              wheel: {
                enabled: true,
              },
              mode: 'x',
              onZoom: ({ chart }) => {
                const min = chart.scales.x.min;
                const max = chart.scales.x.max;

                vitalSignsChartsEventBus.$emit('applyZoom', { min, max });
              },
            },
            pan: {
              enabled: true,
              mode: 'x',

              onPan: ({ chart }) => {
                const min = chart.scales.x.min;
                const max = chart.scales.x.max;

                vitalSignsChartsEventBus.$emit('applyZoom', { min, max });
              },
              onPanComplete: ({ chart }) => {
                const xMax = chart.scales.x.max;
                const xMin = new Date(addMinutes(new Date(chart.scales.x.min), -1)).getTime();

                if (this.chartData.datasets.some((dataset) => dataset.type === 'line')) {
                  vitalSignsChartsEventBus.$emit('panComplete', { xMin, xMax });
                }
              },
            },
            limits: {},
          },
        },

        scales: {
          x: {
            display: true,
            type: 'time',
            time: {
              unit: 'minute',
            },
            title: {
              display: false,
            },
            ticks: {
              source: 'auto',
              maxRotation: 0,
              minRotation: 0,

              callback: (time) => {
                const minute = time.split(' ')[0].split(':')[1];

                if (minute === '00' || minute === '30') {
                  return time;
                }
              },
            },
          },
          y: {
            beginAtZero: false,
            title: {
              display: false,
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },

  watch: {
    zoomLevel: function (visibleAxis) {
      this.chartOptions.scales.x.ticks.callback = (time) => this.getxAxisTimeFormat(time);

      const xAxisdifferenceInMinutes = differenceInMinutes(visibleAxis.max, visibleAxis.min);

      if (xAxisdifferenceInMinutes > 3) {
        this.chartOptions.scales.x.time.unit = 'minute';
      } else {
        this.chartOptions.scales.x.time.unit = 'second';
      }

      if (this.isChartEcg) {
        this.executeWorkerToGetData(xAxisdifferenceInMinutes, visibleAxis.max, visibleAxis.min);
      }
    },

    newChartData(newData) {
      if (new Date(this.xMaxDate) > this.chartOptions.scales.x.max) {
        this.setAxisLimits(this.xMaxDate);
      }

      if (newData) {
        const minAxis = Math.min(...this.chartData.datasets.flatMap((dataset) => dataset.data.map((point) => point.x)));
        const maxAxis = Math.max(...this.chartData.datasets.flatMap((dataset) => dataset.data.map((point) => point.x)));

        const newDataDatetime = new Date(newData.lastDataDatetime).getTime();

        if (newDataDatetime > minAxis) {
          newData.values.forEach((value, i) => {
            if (this.chartData.datasets[i].data.some((point) => point.x === newDataDatetime)) return;

            const data = {
              x: newDataDatetime,
              y: value,
              hasTriggeredAnAlert: newData.hasTriggeredAnAlert,
              observationType: newData.observationType[i],
            };

            const dataColor = newData.hasTriggeredAnAlert
              ? chartColor.incorrectDataColor
              : this.getVitalSignsChartColors(this.activityTypeCode);

            if (differenceInMinutes(new Date(this.xMaxDate), new Date(maxAxis)) > 180) {
              this.chartData.datasets[i].data = [data];
              this.chartData.datasets[i].backgroundColor = [dataColor];
              this.chartData.datasets[i].borderColor = [dataColor];
            } else {
              const index = this.chartData.datasets[i].data.findIndex((point) => point.x > newDataDatetime);

              if (index >= 0) {
                this.chartData.datasets[i].data.splice(index, 0, data);
                this.chartData.datasets[i].backgroundColor.splice(index, 0, dataColor);
                this.chartData.datasets[i]?.borderColor?.splice(index, 0, dataColor);
              } else {
                this.chartData.datasets[i].data.push(data);
                this.chartData.datasets[i].backgroundColor.push(dataColor);
                this.chartData.datasets[i]?.borderColor?.push(dataColor);
              }
            }

            this.setYAxis();
          });
        }
      }
    },
  },

  beforeDestroy: function () {
    vitalSignsChartsEventBus.$off('applyZoom', this.applyZoom);
    LanguageVue.$off('projectLanguage', this.setAxisTranslations);

    if (this.isChartEcg) {
      vitalSignsChartsEventBus.$off('panComplete', this.insertWorkerInQueue);
    }
  },

  created() {
    vitalSignsChartsEventBus.$on('applyZoom', this.applyZoom);
    LanguageVue.$on('projectLanguage', this.setAxisTranslations);

    if (this.isChartEcg) {
      vitalSignsChartsEventBus.$on('panComplete', this.insertWorkerInQueue);
    }

    this.init();
  },

  methods: {
    applyZoom(zoomLevel) {
      this.chartOptions.scales.x.min = zoomLevel.min;
      this.chartOptions.scales.x.max = zoomLevel.max;
    },

    init: function () {
      this.setAxisLimits(this.xMaxDate);
      this.setAxisTranslations();
      const valuesLength = this.patientData?.results?.[0]?.[0]?.values?.length;

      if (valuesLength && this.patientData?.results?.length > 0) {
        let datasets = [];

        this.patientData.results?.forEach((alert) => {
          for (let alertIndex = 0; alertIndex < valuesLength; alertIndex++) {
            let datasetValues = this.getDatasetValues(alert, alertIndex);

            datasets.push({
              type: this.isChartEcg ? 'bar' : 'line',
              borderWidth: this.isChartEcg ? 0 : 2,
              data: datasetValues.data,
              backgroundColor: datasetValues.color,
              normalized: true,
              spanGaps: true,
              fill: false,
              parsing: false,
              ...(!this.isChartEcg && {
                borderColor: datasetValues.color,
                pointStyle: 'circle',
                pointHoverRadius: 5,
                pointRadius: 1.8,
                segment: {
                  borderColor: (ctx) =>
                    ctx.p0.raw?.hasTriggeredAnAlert && ctx.p1.raw?.hasTriggeredAnAlert
                      ? chartColor.incorrectDataColor
                      : this.getVitalSignsChartColors(this.activityTypeCode),
                },
              }),
            });
          }
        });

        this.chartData.datasets = datasets;
      }

      if (this.isChartEcg && this.chartData.datasets[0]?.data) {
        this.defaultData = this.chartData.datasets[0].data;
      }

      this.setYAxis();
      this.areChartDataMounted = true;
    },

    getDatasetValues: function (alerts, alertIndex) {
      let radiusPoints = [];
      let color = [];

      const data = alerts.map((alert) => {
        const alertValue = alert.values[alertIndex];
        const hasTriggeredAnAlert = alertValue.hasTriggeredAnAlert;

        color.push(
          hasTriggeredAnAlert ? chartColor.incorrectDataColor : this.getVitalSignsChartColors(this.activityTypeCode)
        );

        radiusPoints.push((alerts.length === 1 || hasTriggeredAnAlert) && this.radiusPointsSize);

        return {
          x: new Date(alert.time).getTime(),
          y: alertValue.value,
          hasTriggeredAnAlert: hasTriggeredAnAlert,
          observationType: alertValue.observationType,
        };
      });

      return {
        data: data,
        color: color,
        radiusPoints: radiusPoints,
      };
    },

    setAxisLimits: function (xMaxDate) {
      const xMax = new Date(addMinutes(new Date(xMaxDate), 5)).getTime();
      const xMin = new Date(addHours(new Date(xMaxDate), -3)).getTime();

      this.chartOptions.scales.x.max = xMax;
      this.chartOptions.scales.x.min = xMin;

      this.chartOptions.plugins.zoom.limits = {
        x: {
          max: xMax,
          min: xMin,
        },
      };
    },

    setYAxis: function () {
      if (this.isChartEcg) {
        this.chartOptions.scales.y.max = 1;
        this.chartOptions.scales.y.min = -1;
      } else {
        const maxY = Math.max(...this.chartData.datasets.flatMap((dataset) => dataset?.data?.map((point) => point.y)));
        const minY = Math.min(...this.chartData.datasets.flatMap((dataset) => dataset?.data?.map((point) => point.y)));

        if (maxY) {
          this.chartOptions.scales.y.max = Math.round(maxY + (['SAT', 'BDT'].includes(this.activityTypeCode) ? 5 : 10));
        }

        if (minY) {
          this.chartOptions.scales.y.min = Math.max(
            Math.round(minY - (['SAT', 'BDT'].includes(this.activityTypeCode) ? 5 : 10)),
            0
          );
        }
      }
    },

    setAxisTranslations: function () {
      this.chartOptions.scales.x.time.displayFormats = {
        minute: this.getLanguage() === 'fr' ? 'HH:mm' : 'h:mm b',
        second: this.getLanguage() === 'fr' ? 'HH:mm:ss' : 'HH:mm:ss b',
        millisecond: this.getLanguage() === 'fr' ? 'mm:ss.SSS' : 'mm:ss.SSS b',
      };
    },

    getxAxisTimeFormat(time) {
      // Choose what values the x axis should show
      const xAxisDifferenceInMinutes = differenceInMinutes(new Date(this.zoomLevel.max), new Date(this.zoomLevel.min));
      const minute = time.split(' ')[0].split(':')[1];

      const visibleMinutesForLargeDifference = ['00', '30'];
      const visibleMinutesForMediumDifference = ['00', '15', '30', '45'];

      if (xAxisDifferenceInMinutes > 90 && visibleMinutesForLargeDifference.includes(minute)) {
        return time;
      }

      if (
        xAxisDifferenceInMinutes <= 90 &&
        xAxisDifferenceInMinutes > 35 &&
        visibleMinutesForMediumDifference.includes(minute)
      ) {
        return time;
      }

      if (xAxisDifferenceInMinutes < 35) {
        return time;
      }
    },

    insertWorkerInQueue: function ({ xMax, xMin }) {
      // If there is already a worker in execution, insert this one into the queue
      if (this.workerQueue.length > 1) {
        this.workerQueue.slice(this.workerQueue.length - 2);
      }

      this.workerQueue.push({ xMax, xMin });
    },

    executeWorkerToGetData: async function (xAxisdifferenceInMinutes, max, min) {
      // Fetch data if enough zoom else reset the original dataset
      if (xAxisdifferenceInMinutes <= 3) {
        if (differenceInSeconds(max, min) < 4 && this.chartOptions.scales.x.time.unit !== 'millisecond') {
          this.chartOptions.scales.x.time.unit = 'millisecond';
        }

        if (this.ecgWorker) {
          return;
        }

        const xMax = new Date(max).getTime();
        const xMin = new Date(setSeconds(new Date(min), 0)).getTime();

        const areDataNotVisible =
          !this.chartData.datasets?.[1] ||
          !this.chartData.datasets?.[1]?.data?.some((data) => xMax < data.x) ||
          !this.chartData.datasets?.[1]?.data?.some((data) => xMin > data.x);

        if (areDataNotVisible) {
          const request = { xMax, xMin };
          this.processWorkerRequest(request);
        }
      } else if (this.chartData.datasets?.[1]?.data?.length > 0) {
        this.chartData.datasets[1].hidden = true;
        this.chartData.datasets[1].data = [];

        this.chartData.datasets[0].data = JSON.parse(JSON.stringify(this.defaultData));
        this.chartData.datasets[0].hidden = false;

        this.$refs?.vitalSignsChart?.updateChart();
      }
    },

    processWorkerRequest: async function (request) {
      // Async code to avoid an infinite loop when fetching data
      // When Async code is done the worker.onmessage function is executed

      this.showWaitModal = true;
      const { xMax, xMin } = request;

      this.ecgWorker = new Worker(this.ecgWorkerPath); // Create the worker

      this.ecgWorker.onerror = (error) => {
        this.error = error;
        this.ecgWorker.terminate();
        this.ecgWorker = null;
        this.showWaitModal = false;
        this.processNextWorkerRequest();
      };

      const patientInfo = {
        dateTo: format(new Date(xMax), 'yyyy-MM-dd HH:mm:ss'),
        dateFrom: format(new Date(xMin), 'yyyy-MM-dd HH:mm:ss'),
      };

      try {
        const ecgData = await iotRealtimeService.getPatientEcg(this.patientData.patientId, patientInfo);

        this.ecgWorker.postMessage({
          // Execute the worker code (in ecgWorkerPath)
          newData: ecgData,
          millivoltDivisionConstant: this.ecgMillivoltDivisionConstant,
        });
      } catch (error) {
        this.error = error;
        this.processNextWorkerRequest();
        return;
      }

      this.ecgWorker.onmessage = (event) => {
        // If no error in postMessage, this code is executed
        const results = event.data.results;

        this.chartData.datasets = [this.chartData.datasets[0], ...results];
        this.chartData.datasets[0].hidden = true;
        this.$refs?.vitalSignsChart?.updateChart();

        this.ecgWorker.terminate();
        this.ecgWorker = null;
        this.showWaitModal = false;

        this.processNextWorkerRequest();
      };
    },

    processNextWorkerRequest: function () {
      // If there is another worker we execute the next one

      if (this.workerQueue.length > 0) {
        const nextWorkerRequest = this.workerQueue.shift();
        this.processWorkerRequest(nextWorkerRequest);
      }
    },
  },
};
</script>

<style scoped>
.chart-container {
  position: relative;
}

.wait-modal {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
