import PatientResourcesHome from './PatientResourcesHome';

export default {
  moduleName: 'patientsResources',
  routes: [
    {
      path: '/patientsresources',
      name: 'PatientResourcesHome',
      component: PatientResourcesHome,
    },
  ],
};
