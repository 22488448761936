<template>
  <div class="patient-details">
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <v-row :class="{ 'patient-details-row': $vuetify.breakpoint.lgAndUp }">
      <v-col cols="12" lg="auto " md="6">
        <detail-field ref="lastName" :value="lastName" :label="$t('lastName')" />

        <detail-field ref="firstName" :value="firstName" :label="$t('firstName')" />

        <detail-field ref="nasm" :value="nasm" :label="$t('nasm')" />

        <detail-field ref="birthDate" :value="birthDate" :label="$t('birthDate')" />

        <detail-field v-if="isDead" ref="deathDate" :value="deathDate" :label="$t('deathDate')" />
        <detail-field v-else ref="age" :value="age" :label="$t('age')" />

        <detail-field ref="language" :value="language" :label="$t('language')" />
        <detail-field ref="mrn" :value="mrn" :label="$t('mrn')" />
      </v-col>

      <v-col v-if="$vuetify.breakpoint.xs" cols="12">
        <v-divider></v-divider>
      </v-col>

      <v-col cols="12" lg="auto" md="6">
        <detail-field ref="installation" :value="installation" :label="$t('installation')" />

        <detail-field v-if="isAdmitted" ref="roombed" :value="roombed" :label="$t('roombed')" />
        <detail-field ref="establishment" :value="establishment" :label="$t('establishment')" />

        <detail-field v-if="isAdmitted" ref="admissionDate" :value="admissionDate" :label="$t('admissionDate')" />

        <detail-field ref="active" :value="active" :label="$t('active')" />
      </v-col>
      <v-col v-if="$vuetify.breakpoint.xs" cols="12">
        <v-divider></v-divider>
      </v-col>

      <v-col cols="12" lg="auto" md="6">
        <detail-field ref="address" :value="address" :label="$t('address')" />

        <detail-field ref="city" :value="city" :label="$t('city')" />

        <detail-field ref="province" :value="province" :label="$t('province')" />

        <detail-field ref="postalCode" :value="postalCode" :label="$t('postalCode')" />
        <detail-field ref="phone" :value="phone" :label="$t('phone')" />

        <detail-field ref="email" :value="email" :label="$t('email')" />

        <detail-field ref="type" :value="type" :label="$t('type')" />
      </v-col>
      <v-col v-if="$vuetify.breakpoint.xs" cols="12">
        <v-divider></v-divider>
      </v-col>

      <v-col cols="12" lg="auto" md="6">
        <detail-field ref="createdAt" :value="createdAt" :label="$t('createdDate')" />

        <detail-field ref="createdBy" :value="createdBy" :label="$t('createdBy')" />

        <detail-field ref="updatedAt" :value="updatedAt" :label="$t('modifiedDate')" />

        <detail-field ref="updatedBy" :value="updatedBy" :label="$t('modifiedBy')" />
      </v-col>
    </v-row>

    <v-row v-if="hasDci">
      <v-col cols="auto" align-self="center">
        <img height="45px" :src="gustavLogoPath" />
      </v-col>
      <v-col>
        <v-row dense>
          <v-col>
            <div>{{ $t('patientDetails.fromGustav') }}</div>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <v-btn small @click="getDciPatientUrl()">
              {{ $t('patientDetails.openInGustav') }}
              <v-icon right small> mdi-open-in-new </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import translation from '@/translationMixin';
import virtuoseMixin from '@/virtuoseMixin';
import DciService from '@/services/dciService';

export default {
  name: 'PatientDetails',

  mixins: [translation, virtuoseMixin],

  props: {
    patientId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      error: null,
      mrn: '',
      firstName: '',
      lastName: '',
      birthDate: null,
      deathDate: null,
      language: '',
      age: null,
      isDead: true,
      nasm: '',
      isAdmitted: true,
      establishment: null,
      installation: null,
      room: '',
      bed: '',
      admissionDate: '',
      active: null,
      address: '',
      province: '',
      postalCode: '',
      city: '',
      phone: '',
      email: '',
      type: '',
      createdAt: '',
      createdBy: '',
      updatedAt: '',
      updatedBy: '',
      roombed: '',
      hasDci: false,
      gustavLogoPath: '/static/img/gustav-logo.png',
    };
  },
  watch: {
    patientId() {
      if (this.patientId) {
        this.showUserDetails();
      }
    },
  },

  mounted() {
    if (this.patientId) {
      this.showUserDetails();
    }
  },
  methods: {
    showUserDetails: function () {
      let successCallback = function (response) {
        let patient = response.data;
        this.hasDci = patient.hasDci;
        this.mrn = patient.mrn;
        this.firstName = patient.firstName;
        this.lastName = patient.lastName;
        this.birthDate = patient.birthDate;
        this.deathDate = patient.deathDate;
        this.language = patient.language.name;
        this.nasm = patient.nasm;
        this.active = this.$t(patient.active);
        this.establishment = patient.organization?.name ?? null;
        this.installation = patient.facility?.name ?? null;
        this.age = patient.age;
        this.address = patient.address;
        this.city = patient.city;
        this.province = patient.province.name;
        this.postalCode = patient.postalCode;
        this.phone = patient.phone;
        this.email = patient.email;
        this.type = this.$t(patient.patientType.name);
        this.typeCode = patient.patientType.code;
        this.createdAt = patient.created_at;
        this.createdBy = patient.created_by.firstName + ' ' + patient.created_by.lastName;
        this.updatedAt = patient.updated_at;
        this.updatedBy = patient.updated_by.firstName + ' ' + patient.updated_by.lastName;
        this.deathDate ? (this.isDead = true) : (this.isDead = false);

        if (this.typeCode == 'residency' || this.typeCode == 'hospitalized') {
          this.isAdmitted = true;
          this.room = patient.roombed?.room ?? '';
          this.bed = patient.roombed?.bed ?? '';
          this.roombed = this.getRoombedText(patient.roombed);
          this.admissionDate = patient.startDate;
        } else {
          this.isAdmitted = false;
        }
      };

      let errorCallback = (error) => {
        this.error = error;
      };

      this.getResidentById(this.patientId, successCallback, errorCallback);
    },

    async getDciPatientUrl() {
      try {
        let data = await DciService.getDciPatientUrl(this.patientId);
        if (data?.patientFileUrl) {
          window.open(data?.patientFileUrl, '_blank');
        }
      } catch (error) {
        this.error = error;
      }
    },
    getRoombedText(roombed) {
      return roombed && roombed.bed && roombed.room
        ? `${roombed.room} - ${roombed.bed}`
        : roombed.room
        ? roombed.room
        : '';
    },
  },
};
</script>

<style scoped>
.patient-details-row {
  column-gap: 24px;
}
</style>
