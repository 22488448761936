export const subjects = {
  normative: 'virtuose.normative',
  user: 'awscognito.user',
  activity: 'virtuose.activity',
  cohort: 'virtuose.cohort',
  dialogue: 'virtuose.dialogue',
  healthWorker: 'virtuose.healthworker',
  iotDevice: 'virtuose.iotdevice',
  patient: 'virtuose.patient',
  patientActivityProtocol: 'virtuose.patient_activity_protocol',
  patientCohort: 'virtuose.patient_cohort',
  patientHealthWorker: 'virtuose.patient_healthworker',
  patientIotDevice: 'virtuose.patient_iot',
  patientMonitoring: 'virtuose.patientmonitoringalert',
  protocol: 'virtuose.protocol',
  vocalMessage: 'virtuose.vocalmessage',
  patientResource: 'virtuose.patientresource',
};

export const actions = {
  view: 'view',
  add: 'add',
  change: 'change',
  delete: 'delete',
  manage: 'manage',
};
