export default {
  en: {
    SATName: 'Saturation',
    APRName: 'Arterial Pressure',
    CFRName: 'Heart Rate',
    RESName: 'Respiratory Rate',
    BDTName: 'Body Temperature',
    WEIName: 'Weight',
    FDEName: 'Fall',
    INCName: 'Incontinence',
    ECGName: 'ECG',

    SATFullName: 'Saturation',
    APRFullName: 'Arterial Pressure',
    CFRFullName: 'Heart Rate',
    RESFullName: 'Respiratory Rate',
    BDTFullName: 'Body Temperature',
    WEIFullName: 'Weight',
    FDEFullName: 'Fall',
    INCFullName: 'Incontinence',
    ECGFullName: 'ECG',

    SATUnit: '%',
    APRUnit: 'mmHg',
    CFRUnit: 'bpm',
    RESUnit: 'rpm',
    BDTUnit: '°C',
    WEIUnit: 'kg',
    ECGUnit: 'mV',

    'iotRealtime.realtime': 'Real Time',
    'iotRealtime.realtimeDetails': 'Real Time Details',

    'iotRealtime.justNow': 'Just Now',
    'iotRealtime.timeAgo': ' {timeValue} {timeUnit} ago',

    'iotRealtime.noPatientToMonitor': 'No Patient to Monitor',
    'iotRealtime.noMonitoring': 'No Monitoring',
    'iotRealtime.remainingBattery': 'Remaining Battery:',

    'iotRealtime.paginationPagesNumber': '{{startIndex}}-{{endIndex}} of {{totalIotRealtimeData}}',
    'iotRealtime.all': 'All',
    'iotRealtime.noData': 'No Data',

    'iotRealtime.status.fallMode': 'Monitoring',
    'iotRealtime.status.fallDetected': 'Fall',
    'iotRealtime.status.running': 'Running',
    'iotRealtime.status.peopleMode': 'People Detection Mode',
    'iotRealtime.status.motionMode': 'Motion Detection Mode',
    'iotRealtime.status.appCrashed': 'Application Failure',
    'iotRealtime.status.notWorking': 'Not Working',

    'iotRealtime.status.signalLost': 'Signal Lost',
    'iotRealtime.status.faulty': 'Faulty',
    'iotRealtime.status.calculationError': 'Calculation Error',
    'iotRealtime.status.noTransmitter': 'No Transmitter',
    'iotRealtime.status.stripDetachedFromTransmitter': 'Strip Detached from Transmitter',
    'iotRealtime.status.stripDetachedFromBrief': 'Strip Detached from Brief',

    'iotRealtime.detailsNotAvailable': 'Real time details not available: Data entered manually',

    'iotRealtime.lastDateDetails': 'on {{lastDataDate}} at {{lastDataTime}}',
    'iotRealtime.lastDataReceived': 'Last Data: ',
    'iotRealtime.ecgChartXaxisTitle': 'Time (minute:second)',
    'iotRealtime.ecgChartYaxisTitle': 'Millivolts (mV)',
    'iotRealtime.ECGUnit': 'mV',
    'iotRealtime.ecgChartName': 'ECG Chart',

    'iotRealtime.zoomInToSeeData': 'Zoom in to see data',
    'iotRealtime.noPatientVitalSignActivity': 'This patient has no vital signs activity',
  },
  fr: {
    SATName: 'Saturation',
    APRName: 'Pression artérielle',
    CFRName: 'Fréq. cardiaque',
    RESName: 'Fréq. respiratoire',
    BDTName: 'Température corpo.',
    WEIName: 'Poids',
    FDEName: 'Chute',
    INCName: 'Incontinence',
    ECGName: 'ECG',

    SATFullName: 'Saturation',
    APRFullName: 'Pression artérielle',
    CFRFullName: 'Fréquence cardiaque',
    RESFullName: 'Fréquence respiratoire',
    BDTFullName: 'Température corporelle',
    WEIFullName: 'Poids',
    FDEFullName: 'Chute',
    INCFullName: 'Incontinence',
    ECGFullName: 'ECG',

    SATUnit: '%',
    APRUnit: 'mmHg',
    CFRUnit: 'bpm',
    RESUnit: 'rpm',
    BDTUnit: '°C',
    WEIUnit: 'kg',
    ECGUnit: 'mV',

    'iotRealtime.realtime': 'Temps réel',
    'iotRealtime.realtimeDetails': 'Details temps réel',

    'iotRealtime.justNow': "À l'instant",
    'iotRealtime.timeAgo': 'Il y a {timeValue} {timeUnit}',

    'iotRealtime.noPatientToMonitor': 'Aucun patient à afficher',
    'iotRealtime.noMonitoring': 'Pas de suivi',
    'iotRealtime.remainingBattery': 'Pile restante:',

    'iotRealtime.paginationPagesNumber': '{{startIndex}}-{{endIndex}} sur {{totalIotRealtimeData}}',
    'iotRealtime.all': 'Tout',
    'iotRealtime.noData': 'Aucune donnée',

    'iotRealtime.status.fallMode': 'Surveillance',
    'iotRealtime.status.fallDetected': 'Chute',
    'iotRealtime.status.running': 'En exécution',
    'iotRealtime.status.peopleMode': 'Mode détection personne',
    'iotRealtime.status.motionMode': 'Mode détection mouvement',
    'iotRealtime.status.appCrashed': "Échec de l'application",
    'iotRealtime.status.notWorking': 'Ne fonctionne pas',

    'iotRealtime.status.signalLost': 'Signal perdu',
    'iotRealtime.status.faulty': 'Défectueux',
    'iotRealtime.status.calculationError': 'Erreur de calcul',
    'iotRealtime.status.noTransmitter': 'Aucun émetteur',
    'iotRealtime.status.stripDetachedFromTransmitter': "Bande détachée de l'émetteur",
    'iotRealtime.status.stripDetachedFromBrief': 'Bande détachée de la culotte',

    'iotRealtime.detailsNotAvailable': 'Détails temps réel non disponible: Données entrées manuellement',

    'iotRealtime.lastDateDetails': 'le {{lastDataDate}} à {{lastDataTime}}',
    'iotRealtime.lastDataReceived': 'Dernières données: ',
    'iotRealtime.ecgChartXaxisTitle': 'Temps (minute:seconde)',
    'iotRealtime.ecgChartYaxisTitle': 'Millivolts (mV)',
    'iotRealtime.ECGUnit': 'mV',
    'iotRealtime.ecgChartName': 'Graphique ECG',

    'iotRealtime.zoomInToSeeData': 'Faites un zoom avant pour voir les données',
    'iotRealtime.noPatientVitalSignActivity': "Ce patient n'a pas d'activité de signes vitaux",
  },
};
