<template>
  <div class="pa-4 customization-container" justify="space-between">
    <div style="max-height: 100%; overflow-y: auto; overflow-x: hidden">
      <v-treeview
        ref="treeview"
        :items="sortedCustomizedActivities"
        :active.sync="active"
        :open.sync="open"
        color="warning"
        activatable
        return-object
      >
        <template #prepend="{ item }">
          <template v-if="item.overridden">
            <v-icon :color="item.overridden && item === selected ? 'primary' : ''"> mdi-pencil </v-icon>
          </template>
        </template>
      </v-treeview>
    </div>

    <v-divider vertical class="mx-4"></v-divider>

    <div class="selected-activity-container">
      <div
        v-if="!selected || (!activityAlertFields && !isActivityAppointment(selected.activityType))"
        ref="noSelection"
        class="text-h6 grey--text text--lighten-1 font-weight-light"
        style="align-self: center"
      >
        <WaitModal v-if="alertFieldsByActivityTypeLoading" :show="true" />
        <div v-else>{{ $t('selectToCustomize') }}</div>
      </div>
      <div
        v-else-if="!canCustomizeactivityType(selected.activityType)"
        class="text-h6 grey--text text--lighten-1 font-weight-light"
        style="align-self: center"
      >
        {{ $t('noCustomization') }}
      </div>
      <div v-else :key="selected.id" ref="selectedActivity" flat>
        <v-btn color="primary" class="shrink ml-3 mt-0" @click="resetOverriddenStatus()">
          {{ $t('resetActivityValue') }}
        </v-btn>

        <div class="ml-4">
          <template v-if="selected.activityType && selected.activityType.code === ActivityTypes.VID">
            <h3 class="mt-3 mb-3">
              {{ $t('customizeTriggers') }}
            </h3>

            <v-form ref="videoCallTriggerForm">
              <div v-for="(trigger, index) in selected.overriddenTriggers" :key="index">
                <v-row>
                  <v-col cols="1" class="pt-7">
                    <div v-if="selected.overridden && trigger.overriddenTrigger">
                      <v-icon> mdi-pencil </v-icon>
                    </div>
                  </v-col>

                  <v-col>
                    <DateTimePickerField
                      ref="triggerTime"
                      v-model="trigger.startTime"
                      :label="$t('timeOnly')"
                      required
                      max="200"
                      picker-type="time"
                      @input="updateTrigger(selected, index)"
                    />
                  </v-col>

                  <v-col>
                    <div v-if="selected.overridden && trigger.overriddenTrigger" class="pt-4">
                      <v-btn
                        ref="resetTrigger"
                        color="primary"
                        class="shrink ml-3 mt-0"
                        @click="resetOverriddenTriggerStatus(selected, index)"
                      >
                        {{ $t('resetValue') }}
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </template>

          <template v-else>
            <div v-if="isActivityAppointment(selected.activityType)">
              <PatientActivityCustomizeAppointment
                :selected="selected"
                :is-trigger-match="isTriggerMatch"
                :check-overridden-trigger="checkOverriddenTrigger"
              ></PatientActivityCustomizeAppointment>
            </div>
            <div v-if="isActivityDialogue(selected.activityType)">
              <PatientActivityCustomizeDialogues
                :selected="selected"
                :is-alert-match="isAlertMatch"
                :is-trigger-match="isTriggerMatch"
                :check-overridden-trigger="checkOverriddenTrigger"
              ></PatientActivityCustomizeDialogues>
            </div>
            <div v-if="!isActivityAppointment(selected.activityType)">
              <h3 class="mt-3 mb-3">
                {{ $t('customizeThreshold') }}
              </h3>

              <div v-for="(alert, index) in getSelectedOverriddenAlerts()" :key="index">
                <v-row>
                  <v-col cols="1">
                    <div v-if="selected.overridden && alert.overriddenAlert" class="pt-4">
                      <v-icon> mdi-pencil </v-icon>
                    </div>
                  </v-col>
                  <v-col cols="auto">
                    <div class="pt-4 ml-n5">
                      <span class="font-weight-bold">{{ index + 1 }}.</span>
                    </div>
                  </v-col>
                  <v-col>
                    <v-row dense>
                      <v-col v-if="isActivityDialogue(selected.activityType)">
                        <v-text-field
                          ref="dialogueAlert"
                          :label="$t('dialogue')"
                          item-text="dialogueName"
                          :value="getDialogueName(alert.dialogueId)"
                          readonly
                          filled
                          disabled
                        />
                      </v-col>
                      <v-col v-if="!isActivityDialogue(selected.activityType)">
                        <v-text-field
                          ref="parametersType"
                          :value="$t(`monitoringValue_${alert.monitoringValue}`)"
                          :label="$t('parametersType')"
                          readonly
                          filled
                          disabled
                          :rules="[validationRules.required]"
                        />
                      </v-col>
                      <v-col>
                        <v-text-field
                          ref="alertType"
                          :value="$t(`type_${alert.type}`)"
                          :label="$t('alertType')"
                          readonly
                          filled
                          disabled
                          :rules="[validationRules.required]"
                        />
                      </v-col>
                      <v-col v-if="!isActivityDialogue(selected.activityType)">
                        <v-text-field
                          v-if="!(alert.type === 'nottaken')"
                          ref="thresholdText"
                          v-model.number="alert.threshold"
                          filled
                          :label="$t('thresholdText')"
                          class="required-indicator"
                          :rules="[
                            validationRules.required,
                            validationRules.inRange(1, alert.type === 'variation' ? 100 : 500),
                          ]"
                          type="number"
                          hide-spin-buttons
                          max="200"
                          :suffix="getSuffix(alert.unitType, activityAlertFields.unitType)"
                          :append-icon="
                            activityAlertFields.unitType.length > 2 ||
                            (alert.type === 'variation' && alert.monitoringValue !== 'SAT')
                              ? 'mdi-autorenew'
                              : undefined
                          "
                          @input="updateOverriddenAlertStatus(selected, index)"
                          @click:append="toggleUnitType(selected, alert, index)"
                        />
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-if="alert.type == 'variation'"
                          ref="dateLimitText"
                          v-model.number="alert.length"
                          type="number"
                          :rules="[validationRules.required, validationRules.inRange(1, 50)]"
                          filled
                          hide-spin-buttons
                          :label="$t('dateLimitText')"
                          :append-icon="activityAlertFields.lengthType.length > 1 ? 'mdi-autorenew' : undefined"
                          class="required-indicator"
                          max="31"
                          :suffix="getSuffix(alert.lengthType, activityAlertFields.lengthType)"
                          @input="updateOverriddenAlertStatus(selected, index)"
                          @click:append="toggleLengthType(selected, alert, index)"
                        >
                        </v-text-field>
                        <div v-for="dialogue in selected.overriddenDialogues" :key="dialogue.id">
                          <p
                            v-if="shouldShowAlertMessage(dialogue, alert)"
                            ref="showAlertMessage"
                            class="mt-n5 text-caption"
                          >
                            {{ alertMessages }}
                          </p>
                        </div>
                      </v-col>
                      <v-col>
                        <div v-if="shouldShowAlertResetButton(alert)" class="pt-4">
                          <v-btn
                            ref="resetAlert"
                            color="primary"
                            class="shrink ml-3 mt-0"
                            @click="resetOverriddenAlertStatus(selected, index)"
                          >
                            {{ $t('resetValue') }}
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import translation from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import validationRulesMixin from '@/validationRulesMixin';
import PatientActivityCustomizeDialogues from './PatientActivityCustomizeDialogues';
import PatientActivityCustomizeAppointment from './PatientActivityCustomizeAppointment';
import activityService from '@/services/activityService';
import { ActivityTypes } from '@/components/PatientMonitoring/constants';
import patientActivityMixin from './patientActivityMixin';

export default {
  name: 'PatientActivityCustomize',

  components: { PatientActivityCustomizeDialogues, PatientActivityCustomizeAppointment },
  mixins: [translation, accessibility, patientActivityMixin, validationRulesMixin],

  props: {
    customizedActivities: {
      type: Array,
      default: () => [],
    },
    isActivityDialogue: {
      type: Function,
      required: true,
    },
    isActivityAppointment: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      active: [],
      open: [],
      alerts: [],
      cachedAlertFieldsByActivityType: [],
      alertFieldsByActivityTypeLoading: false,
      ActivityTypes: ActivityTypes,
    };
  },
  computed: {
    selected() {
      if (!this.active.length) return undefined;

      return this.active[0];
    },

    activityAlertFields() {
      let activityTypeId = this.selected?.activityType?.id;
      if ((activityTypeId ?? null) === null || this.isActivityAppointment(this.selected?.activityType)) {
        return null;
      }

      return this.cachedAlertFieldsByActivityType.find((x) => x.id === activityTypeId)?.fields ?? null;
    },

    alertMessages() {
      if (this.selected && this.selected.overriddenDialogues) {
        this.selected.overriddenDialogues.forEach((dialogue) => {
          if (!dialogue?.enabled) {
            this.selected.overriddenParameters.alerts.filter((alert) => alert.dialogueId === dialogue.id);
          }
        });
        return this.$t('alertMessages');
      }
      return '';
    },

    sortedCustomizedActivities() {
      return this.customizedActivities.slice().sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    },

    isAlertOverridden() {
      const alerts = this.selected?.overriddenParameters?.alerts;
      return this.checkOverriddenAlert(alerts);
    },
    isTriggerOverridden() {
      const triggers = this.selected?.overriddenTriggers;
      return this.checkOverriddenTrigger(triggers);
    },

    isDialogueOverridden() {
      if (this.selected && this.selected.overriddenDialogues) {
        return this.selected.overriddenDialogues.some((dialogue) => !dialogue?.enabled);
      }
      return false;
    },

    isAnyOverridden() {
      return this.isAlertOverridden || this.isTriggerOverridden || this.isDialogueOverridden;
    },
  },

  watch: {
    async 'selected.activityType.id'(activityTypeId) {
      if ((activityTypeId ?? null) !== null) {
        await this.getElementsOnactivityType();
      }
    },
    activityAlertFields() {
      if (this.activityAlertFields) {
        this.alerts.filter((x) => !x.initialized).forEach((alert) => this.setAlertDefaultValues(alert));
      }
    },

    isDialogueOverridden(newStatus) {
      if (this.selected) {
        this.selected.overridden =
          newStatus ||
          this.checkOverriddenTrigger(this.selected.overriddenTriggers) ||
          this.checkOverriddenAlert(this.selected.overriddenParameters?.alerts);
      }
    },

    'selected.overriddenParameters.alerts'(newAlerts) {
      if (newAlerts) {
        this.selected.overridden = this.isAnyOverridden;
      }
    },
    'selected.overriddenTriggers'(newTriggers) {
      if (newTriggers) {
        this.selected.overridden = this.isAnyOverridden;
      }
    },
    'selected.overriddenDialogues'(newStatus) {
      if (newStatus) {
        this.selected.overridden = this.isAnyOverridden;
      }
    },
  },

  methods: {
    /* eslint-disable */

    shouldShowAlertMessage(dialogue, alert) {
      return !dialogue?.enabled && alert?.dialogueId === dialogue?.id;
    },
    shouldShowAlertResetButton(alert) {
      return this.selected.overridden && alert?.overriddenAlert;
    },
    getDialogueName(dialogueId) {
      const dialoguesList = this.selected.overriddenDialogues;
      if (dialoguesList && dialoguesList.length > 0) {
        const dialogue = dialoguesList.find((item) => {
          return item.id === dialogueId;
        });
        return dialogue ? dialogue.dialogueName : '';
      }
      return '';
    },
    getSelectedOverriddenAlerts() {
      if (this.selected && this.selected.overriddenParameters) {
        return this.selected.overriddenParameters.alerts;
      }
      return [];
    },

    canCustomizeactivityType(activityType) {
      const uncustomizable = [ActivityTypes.FDE, ActivityTypes.INC, ActivityTypes.RXA, ActivityTypes.PRE];
      return !uncustomizable.includes(activityType.code);
    },

    async getElementsOnactivityType() {
      if (this.isActivityAppointment(this.selected.activityType)) return;

      let activityTypeId = this.selected?.activityType.id;
      this.alertFieldsByActivityTypeLoading = true;

      if (!this.cachedAlertFieldsByActivityType?.some((x) => x.id === activityTypeId)) {
        try {
          let fieldData = await activityService.getActivityAlertFieldsByActivityType(activityTypeId);

          let getFieldValues = (items, key) => {
            if (!items) {
              return null;
            }
            return items.map((value) => {
              return {
                value: value,
                text: this.$t(`${key}_${value}`),
              };
            });
          };

          let fields = {
            lengthType: getFieldValues(fieldData.lengthType, 'lengthType'),
            monitoringValue: getFieldValues(fieldData.monitoringValue, 'monitoringValue'),
            recipients: getFieldValues(fieldData.recipients, 'recipients'),
            type: getFieldValues(fieldData.type, 'type'),
            unitType: getFieldValues(fieldData.unitType, 'unitType'),
          };

          this.cachedAlertFieldsByActivityType.push({
            id: activityTypeId,
            fields: fields,
          });
        } catch (error) {
          this.error = error;
        }
      }
      this.alertFieldsByActivityTypeLoading = false;
    },

    setAlertDefaultValues(alert) {
      if (this.activityAlertFields) {
        if (this.activityAlertFields.monitoringValue?.length === 1) {
          alert.monitoringValue = this.activityAlertFields.monitoringValue[0].value;
        }
        if (this.activityAlertFields.type?.length === 1) {
          alert.type = this.activityAlertFields.type[0].value;
        }

        // Since there's no dropdown to select the unitType / lengthType (it's inline in the unit / length v-text),
        // we automatically select the first one (otherwise the UI would be too confusing)
        if (this.activityAlertFields.unitType?.length > 0) {
          alert.unitType = this.activityAlertFields.unitType[0].value;
        }
        if (this.activityAlertFields.lengthType?.length > 0) {
          alert.lengthType = this.activityAlertFields.lengthType[0].value;
        }

        alert.initialized = true;
      }
    },

    getSuffix(value, items) {
      if (value !== null && items) {
        return items?.find((x) => x.value === value)?.text;
      }
    },

    toggleLengthType(activity, alert, alertIndex) {
      let idx = this.activityAlertFields?.lengthType.findIndex((x) => x.value === alert?.lengthType) + 1;
      if (idx === this.activityAlertFields?.lengthType.length) {
        idx = 0;
      }
      if (alert?.lengthType) {
        alert.lengthType = this.activityAlertFields?.lengthType[idx].value;
      }
      this.updateOverriddenAlertStatus(activity, alertIndex);
    },

    toggleUnitType(activity, alert, alertIndex) {
      let idx = this.activityAlertFields?.unitType.findIndex((x) => x.value === alert?.unitType) + 1;
      if (idx === this.activityAlertFields?.unitType.length) {
        idx = 0;
      }

      if (alert?.unitType) {
        alert.unitType = this.activityAlertFields?.unitType[idx].value;
      }
      this.updateOverriddenAlertStatus(activity, alertIndex);
    },

    isAlertMatch(alertIndex) {
      let defaultParameters = this.selected.defaultParameters;
      let overriddenParameters = this.selected.overriddenParameters;
      const alertsMatch =
        JSON.stringify(overriddenParameters.alerts[alertIndex], (key, value) => {
          if (key !== 'overriddenAlert') {
            return value;
          }
        }) ===
        JSON.stringify(defaultParameters.alerts[alertIndex], (key, value) => {
          if (key !== 'overriddenAlert') {
            return value;
          }
        });
      return alertsMatch;
    },

    isTriggerMatch(triggerIndex) {
      let defaultTriggers = this.selected.defaultTriggers;
      let overriddenTriggers = this.selected.overriddenTriggers;

      const triggersMatch =
        JSON.stringify(overriddenTriggers[triggerIndex], (key, value) => {
          if (key !== 'overriddenTrigger') {
            return value;
          }
          return undefined;
        }) ===
        JSON.stringify(defaultTriggers[triggerIndex], (key, value) => {
          if (key !== 'overriddenTrigger') {
            return value;
          }
          return undefined;
        });
      return triggersMatch;
    },

    // Reinitialise aux valeurs par defaut
    resetOverriddenStatus() {
      this.selected.overridden = false;
      this.selected.overriddenParameters = JSON.parse(JSON.stringify(this.selected.defaultParameters));
      this.selected.overriddenDialogues = JSON.parse(JSON.stringify(this.selected.defaultDialogues));
      this.selected.overriddenTriggers = JSON.parse(JSON.stringify(this.selected.defaultTriggers));
    },

    // Verifie si au moins une alerte a un overriddenAlert a true
    checkOverriddenAlert(alerts) {
      if (alerts && Array.isArray(alerts)) {
        return alerts.some((alert) => alert?.overriddenAlert);
      }
      return false;
    },

    checkOverriddenTrigger(triggers) {
      if (triggers && Array.isArray(triggers)) {
        return triggers.some((trigger) => trigger.overriddenTrigger);
      }
      return false;
    },

    checkOverriddenDialogue(dials) {
      if (dials && Array.isArray(dials)) {
        return dials.some((dial) => !dial.enabled);
      }
      return false;
    },

    // Met a jour la propriete overridden en fonction des status overriddenAlert + reinitialise la propriete overriddenAlert d'une alerte spécifique
    // Si au moins une overriddenAlert est true, overridden sera true. Si toutes sont false, overridden sera false
    resetOverriddenAlertStatus(activity, alertIndex) {
      let defaultParameters = activity.defaultParameters;
      let overriddenParameters = activity.overriddenParameters;
      overriddenParameters.alerts = overriddenParameters.alerts.map((alert, index) => {
        if (index === alertIndex) {
          const { overriddenAlert, ...rest } = defaultParameters.alerts[alertIndex];
          return rest;
        } else {
          return alert;
        }
      });

      const overridden = this.checkOverriddenAlert(overriddenParameters.alerts);
      activity.overridden = overridden;
    },

    // Met a jour la propriete overriddenAlert d'une alerte specifique en la comparant avec l'index
    // Si l'utilisateur rentre manuellement les valeurs par défauts, l'alerte n'est pas considérée comme ayant été modifiée
    updateOverriddenAlertStatus(activity, alertIndex) {
      let overriddenParameters = activity.overriddenParameters;

      const overriddenAlert = !this.isAlertMatch(alertIndex);
      overriddenParameters.alerts = overriddenParameters.alerts.map((alert, index) => {
        if (index === alertIndex) {
          if (overriddenAlert) {
            return {
              ...alert,
              overriddenAlert: overriddenAlert,
            };
          } else {
            const { overriddenAlert, ...rest } = alert;
            return rest;
          }
        } else {
          return alert;
        }
      });

      const overridden = this.checkOverriddenAlert(overriddenParameters.alerts);
      activity.overridden = overridden;
    },
  },
};
</script>

<style scoped>
.customization-container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: calc(90vh - 300px);
}

.selected-activity-container {
  flex-grow: 1;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
