import api from './baseApiService';

class PatientResourceService {
  async getResources() {
    return await api.get('patient_resources');
  }

  async getResourceById(id) {
    return await api.get(`patient_resource/${id}`);
  }

  async createResource(data) {
    return await api.post('patient_resource/', data);
  }

  async updateResource(id, data) {
    return await api.post(`patient_resource/update/${id}`, data);
  }

  async deleteResource(id) {
    return await api.delete(`patient_resource/${id}/delete`);
  }
}

export default new PatientResourceService();
